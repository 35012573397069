/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.tiraecol.net/"
  }, React.createElement(_components.img, {
    src: "images/tiraecol-231.png",
    alt: "Tira Ecol"
  }))), "\n", React.createElement(_components.p, null, "vía ", React.createElement(_components.a, {
    href: "http://www.tiraecol.net/modules/comic/comic.php?content_id=231"
  }, "Cuando todo sea Google"), "."), "\n", React.createElement(_components.p, null, "Últimamente parece que Google está en todos lados y eso me da muy mala espina, pude que la gente me llame conspiranoico, pero que una sola empresa aglutine prácticamente toda la información que genera en Internet una sola persona no me gusta, esto hace a Google posiblemente la empresa mas poderosa del mundo, pero no nos confiemos, muy de cerca le sigue Facebook."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
